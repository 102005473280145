import React from 'react';
import AuthProvider from '../context/auth-context';
import Layout from '../components/layout/layout';
import '../styles/style.scss';
import App from './app';

export default function Dashboard () {
    return (
        <App />
    )
}